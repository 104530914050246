export default function sortData(data, sortValue, sortDirection) {
    return data.sort((a, b) => {
        if (typeof a[sortValue] === "string") {
            if (sortDirection === 'up') {
                return a[sortValue].localeCompare(b[sortValue]);
            } else {
                return b[sortValue].localeCompare(a[sortValue]);
            }
        } else {
            if (sortDirection === 'up') {
                return a[sortValue] - b[sortValue];
            } else {
                return b[sortValue] - a[sortValue];
            }
        }
    });
}